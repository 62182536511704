
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.card__title {
  display: inline; // Required in order for the custom multiline underline to work
  font-style: normal;
}
