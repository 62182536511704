
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













@function clone-shadows($count) {
  $val: '';

  @for $i from 1 through $count {
    $val: #{$val};

    @if ($i > 1) {
      $val: #{$val + ', '};
    }

    $val: #{$val + 'calc(var(--shadow-distance) * #{$i}) 0 0 currentColor'};
  }

  @return $val;
}

.marquee {
  @include liquid(font-size, 20px, 36px);

  overflow: hidden;
  color: $c-text-light;
  white-space: nowrap;
  background: $c-surface-dark;
  border-radius: 25px;
  user-select: none;

  &:hover {
    .marquee__inner {
      animation-play-state: paused;
    }
  }
}

.marquee__inner {
  --shadow-distance: 0;

  display: inline-block;
  padding-right: 1em;
  line-height: 1;
  text-shadow: clone-shadows(10);
  animation: marquee linear 30s infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100%));
  }
}
