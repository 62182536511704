
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.title {
  // @include liquid(font-size, 12px, 14px);
  @include liquid(padding-bottom, 20px, 26px);

  display: block;
  margin-bottom: 2rem;
  border-bottom: 1px currentColor solid;
}

.card__subtitle {
  font-size: 1.8rem;
  font-style: italic;
  opacity: 0.6;
  /* stylelint-disable */
  // Not fully supported
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* stylelint-enable */
}
