
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































$card-gap-x: 5rem;

.card__visual-container {
  --shadow-opacity: 0;

  @include liquid('margin-bottom', 12px, 35px);
}

.card__marquee {
  width: 100%;
  border-radius: 0;
  background: $c-fluo;
  font-size: 14px;
  color: $c-text-dark;
  padding: 9px 0;

  ::v-deep {
    .marquee__inner {
      padding-right: 2px;
      animation-duration: 20s;
    }
  }
}

.card__visual {
  width: 100%;

  img {
    width: 100%;
  }
}

.shadow {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
    opacity: var(--shadow-opacity);
    transition: opacity 250ms linear;
  }
}

.card__secondary-visual,
[class*='card__secondary-visual--'] {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: all 500ms $ease-out-expo;
  pointer-events: none;

  $distance: 50px;

  &[class*='--0'] {
    z-index: -1;
    left: 0;
    width: var(--sv-1-w);
    transform: translate(-50%, calc(var(--sv-1-y) + #{$distance}));
    transition-delay: $secondary-card-visual-transition-delay;
  }

  &[class*='--1'] {
    right: 0;
    width: var(--sv-2-w);
    transform: translate(50%, calc(var(--sv-2-y) + #{$distance}));
  }
}
