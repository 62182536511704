
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























$card-gap-x: 5rem;

.archive {
  overflow-x: hidden; // Prevent secondary visuals from adding horizontal scroll
}

.listing {
  --columns: 1;

  @include liquid('row-gap', 50px, 120px);

  position: relative; // Display overlay over header and footer
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  column-gap: $card-gap-x;
  margin-bottom: 10rem;
  padding: 0;
  list-style-type: none;

  @include mq($from: xs) {
    --columns: 2;
  }

  @include mq($from: s) {
    --columns: 3;
  }

  @include mq($from: l) {
    --columns: 4;
  }
}

.card {
  --sv-1-w: 80%;
  --sv-1-y: 0%;
  --sv-2-w: 50%;
  --sv-2-y: 0%;
}

.card__link {
  position: relative;
  z-index: 1;
  display: block;
  color: currentColor;
  line-height: 1.3;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    // Bring the cards over its siblings
    z-index: 2;

    .card__visual-container {
      --shadow-opacity: 1;
    }

    @media (hover: hover) {
      [class*='card__secondary-visual--'] {
        opacity: 1;

        &[class*='--0'] {
          transform: translate(-50%, var(--sv-1-y));
          transition-delay: 0ms;
        }

        &[class*='--1'] {
          transform: translate(50%, var(--sv-2-y));
          transition-delay: $secondary-card-visual-transition-delay;
        }
      }
    }
  }
}

.card__details {
  margin-bottom: 2rem;
}
