
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































.card__details {
  display: flex;

  .subtitle:not(:first-of-type) {
    margin-left: 1rem;
  }
}

.designer-tags {
  @include liquid(font-size, 12px, 14px);
  @include liquid(padding-top, 20px, 26px);

  position: relative; // Prevent link from going UNDER fluo blurry overlay
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0 0;
  border-top: 1px currentColor solid;
}

.card__link {
  &:hover {
    + .designer-tags {
      z-index: $z-index-blur-overlay;
    }
  }
}

.designer-tags__item {
  margin: 0 4px 8px;
  padding: 0.9rem 1.6rem;
  line-height: 1;
  text-decoration: none;
  border: 1px solid currentColor;
  border-radius: 25px; // Arbitray number

  &.is-button {
    padding: 0;

    button {
      padding: 0.9rem 1.6rem;
      margin: 0;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
